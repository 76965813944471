import React, { useState, useEffect } from 'react';
import NavBar from './Navbar'
import { Table, Container, Button, Alert, Modal } from 'react-bootstrap'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'


export default function Pages() {
    const [pages, setPages] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteTitle, setDeleteTitle] = useState(null);
    const [error, setError] = useState(null)
    const [pageUpdated, setPageUpdated] = useState(false)


    const history = useHistory();
    function RenderError() {
        if (error === null) {
            return null
        } else {
            return <Alert className="m-2" variant="warning">{error}</Alert>
        }
    }
    function DeleteModal(event) {
        let page = JSON.parse(event.currentTarget.value)
        setDeleteId(page.id)
        setDeleteTitle(page.title)
        handleShow()
    }
    useEffect(() => {
        axios.get('/api/v1/page/get.php?all=true')
            .then(function (response) {
                if (response.data.message === "OK") {
                    setPages([]);
                    for (var i = 0, len = Object.keys(response.data.pages).length; i < len; ++i) {
                        let page = JSON.stringify(response.data.pages[i])
                        setPages(pages => pages.concat(<tr key={response.data.pages[i].id}><td>{response.data.pages[i].id}</td><td>{response.data.pages[i].title}</td><td><Link to={'/pages/edit/' + response.data.pages[i].id}><Button variant="secondary">Edit</Button></Link></td><td><Button variant="danger" onClick={DeleteModal} value={page}>Delete</Button></td></tr>))
                    }
                } else if (response.data.message === 'You must be signed in to perform that action') {
                    history.push('/login')
                } else {
                    RenderError(response.data.message)
                }
            })
            .catch(function (error) {
                RenderError(error.message)
            });
    }, [pageUpdated])

    function DeletePage() {
        axios.post('/api/v1/page/delete.php', {
            id: deleteId
        }).then(function (response) {
            if (response.data.message === 'OK') {
                handleClose()
                setPageUpdated(!pageUpdated);
            } else if (response.data.message === 'You must be signed in to perform that action') {
                history.push('/login')
            } else {
                setError(response.data.message)
            }
        })
    }
    return (
        <>
            <NavBar loginPage={false} page={"pages"}></NavBar>
            <Container className="top-margin">
                <Link to='/'><Button variant="outline-secondary">Back Home</Button></Link>
                <Link to='/pages/add'><Button variant="outline-primary" className="float-right">Add Page</Button></Link>
                <RenderError></RenderError>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Title</th>
                            <th>Edit Page</th>
                            <th>Delete Page</th>
                        </tr>
                    </thead>
                    <tbody>{pages}</tbody>
                </Table>
            </Container>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete {deleteTitle}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete the page?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
          </Button>
                    <Button variant="danger" onClick={DeletePage}>
                        Delete
          </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
