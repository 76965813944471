import React, { useState, useEffect } from 'react';
import NavBar from './Navbar';
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import { Form, Button, Container, Alert, Row, Col } from 'react-bootstrap';

export default function Account() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const history = useHistory();
    function Update(e) {
        e.preventDefault();
        if(password !== confirmPassword){
            setSuccess(null)
            setError("Passwords Don't match")
            return;
        }
        axios.post('/api/v1/user/update.php', {
            firstName: firstName,
            lastName: lastName,
            email: email,
            password: password,
            confirmPassword: password
        }).then(function (response) {
            if (response.data.message === 'OK') {
                setError(null)
                setSuccess('Account updated successfully')
            } else if(response.data.message === 'You must be signed in to perform that action'){
                history.push('/login')
            } else {
                setError(response.data.message)
            }
        }).catch(function (error) {
            setError(error.message)
        });
    }
    function UpdateSuccess() {
        if (success === null) {
            return null
        } else {
            return <Alert className="m-2" variant="success">{success}</Alert>
        }
    }
    useEffect(() => {
        axios.get('/api/v1/user/get.php?id=me')
            .then(function (response) {
                if (response.data.message === "OK") {
                    setFirstName(response.data.user.first_name)
                    setLastName(response.data.user.last_name)
                    setEmail(response.data.user.email)
                }else if(response.data.message === 'You must be signed in to perform that action'){
                    history.push('/login')
                } else {
                    RenderError(response.data.message)
                }
            })
            .catch(function (error) {
                RenderError(error.message)
            });
    }, [])
    function RenderError() {
        if (error === null) {
            return null
        } else {
            return <Alert className="m-2" variant="warning">{error}</Alert>
        }
    }
    return (
        <>
            <NavBar loginPage={false} page={"account"}></NavBar>
            <Container>
                <Form className="login-form" onSubmit={Update}>
                    <Link to='/'><Button variant="outline-secondary">Back Home</Button></Link>
                    <h2 className="text-center p-2">Edit Your Account</h2>
                    <RenderError></RenderError>
                    <UpdateSuccess></UpdateSuccess>
                    <Form.Group>
                        <Row>
                            <Col>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" placeholder="First Name" maxLength="20" minLength="1" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="First Name" maxLength="20" minLength="1" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Email Address" maxLength="50" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" minLength="6" onChange={(e) => setPassword(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Confirm Password" minLength="6" onChange={(e) => setConfirmPassword(e.target.value)} />
                    </Form.Group>
                    <Button variant="primary" type="submit" value="Submit" className="float-right">Update</Button>
                </Form>
            </Container>
        </>
    )
}