import React, { useState, useEffect } from 'react';
import NavBar from './Navbar';
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import Cookies from 'js-cookie';
import { Form, Button, Container, Alert } from 'react-bootstrap';

export default function Login() {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [error, setError] = useState(null);
    const history = useHistory();
    useEffect(() => {
        axios.get('/api/v1/isLoggedIn.php')
            .then(function (response) {
                if (response.data.message === "true") {
                    history.goBack();
                } else {
                    return
                }
            })
            .catch(function (error) {
                RenderError(error.message)
            });
    }, [])
    function Submit(e) {
        e.preventDefault();
        let cookie = Cookies.get('Permission')
        axios.post('/api/v1/login.php', {
            email: email,
            password: password
          })
          .then(function (response) {
            if(response.data.message !== "OK"){
                setError(response.data.message);
            } else {
                if(cookie !== undefined){
                    history.goBack();
                } else {
                    window.location.href = '/'
                }
            }
          })
          .catch(function (error) {
            setError(error.message);
          });
    }
    function RenderError(){
        if(error === null){
            return null
        } else {
            return <Alert className="m-2" variant="warning">{error}</Alert>
        }
    }
    return (
        <>
            <NavBar></NavBar>
            <Container>
                <Form className="login-form" onSubmit={Submit}>
                    <Link to='/'><Button variant="outline-secondary">Back Home</Button></Link>
                    <h2 className="text-center p-2">Login</h2>
                    <RenderError></RenderError>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Email Address" maxLength="50" onChange={(e) => { setEmail(e.target.value) }} required />
                    </Form.Group>
                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" minLength="6" onChange={(e) => { setPassword(e.target.value) }} required />
                    </Form.Group>
                    <Button variant="primary" type="submit" value="Submit" className="float-right">Login</Button>
                </Form>
            </Container>
        </>
    )
}