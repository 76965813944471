import React, { useState, useEffect, useRef, useCallback } from 'react';
import NavBar from './Navbar'
import axios from 'axios'
import { Container, Alert, Col, Row, Form, Button, Nav } from 'react-bootstrap';
import Gallery2 from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from "react-images";
import Slider from 'react-slick'
import { useLocation, Link } from 'react-router-dom';
import BeatLoader from "react-spinners/BeatLoader";
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function RenderPage() {
    const [page, setPage] = useState();
    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;
        if (currentPath === '/') {
            setPage(<Homepage />)
        } else if (currentPath === '/contact') {
            setPage(<Contact />)

        } else {
            let url = String(currentPath).substring(6);
            setPage(<Page id={url} />)
        }
    }, [location]);
    return (
        <>
            <NavBar loginPage={false} page="home"></NavBar>
            {page}
        </>
    )
    function Homepage(props) {
        const [error, setError] = useState(null)
        const [selectedWork, setSelectedWork] = useState([])
        const [sliderImages, setSliderImages] = useState([])
        const [isLoading, setIsLoading] = useState(true)
        const [links, setLinks] = useState([])
        const [currentImage, setCurrentImage] = useState(0);
        const [viewerIsOpen, setViewerIsOpen] = useState(false);
        const [galleryHeight, setGalleryHeight] = useState(250);
        function setGalHeight() {
            const { innerWidth: width, innerHeight: height } = window;
            if (width < 500) {
                setGalleryHeight(50);
            } else {
                setGalleryHeight(250)
            }
        }
        const openLightbox = useCallback((event, { photo, index }) => {
            setCurrentImage(index);
            setViewerIsOpen(true);
        }, []);

        const closeLightbox = () => {
            setCurrentImage(0);
            setViewerIsOpen(false);
        };
        const CarouselHeader = () => {
            return (
                <div className="header-container">
                    <div role="button" id="close1" onClick={closeLightbox}>
                        <div className="close2">
                            <div className="close3"></div>
                        </div>
                    </div>
                </div>
            )
        }
        const imageRenderer = useCallback(
            ({ index, left, top, key, photo }) => (
                <div className="image-overlay-container" style={{ margin: '2px' }}>
                    <LazyLoadImage
                        alt={photo.alt}
                        effect="blur"
                        className="image-hover"
                        width={photo.width}
                        height={photo.height}
                        placeholderSrc={photo.srcSet.lazyLoadPlaceholder}
                        src={photo.srcSet.thumbnail}
                        style={{ top: top, left: left }}
                    />
                    <div className="overlay text-center" onClick={(e) => { openLightbox(e, { photo, index }) }}>
                        <p>{photo.alt}</p>
                        <img alt="Eye SVG" src='/api/temp/eye.svg' />
                    </div>
                </div>
            )
        );
        useEffect(() => {
            setGalHeight()
            axios.get('/api/v1/page/get.php?page=home')
                .then(function (response) {
                    if (response.data.message === "OK") {
                        let image = response.data.page.images
                        if (image !== undefined) {
                            setSelectedWork(image)
                        }
                    }
                }).catch(function (error) {
                    setError(error.message)
                });
            axios.get('/api/v1/page/get.php?page=home&slider')
                .then(function (response) {
                    if (response.data.message === "OK") {
                        let images = response.data.page.images
                        if (images === undefined) {
                            setIsLoading(false)
                        }
                        images.forEach(function (item, index) {
                            if (index === 0) {
                                setSliderImages(sliderImages => sliderImages.concat(
                                    <div><div className='carousel-image' style={{ backgroundImage: `url(${item.src})` }}></div></div>
                                ))
                            } else {
                                setSliderImages(sliderImages => sliderImages.concat(
                                    <div><LazyLoadImage alt="Slider Image" src={item.src} delayTime={index * 5000} className="slider-image" onLoad={stopLoading()} /></div>
                                ))
                            }

                        })
                    }
                }).catch(function (error) {
                    setError(error.message)
                });
            axios.get('/api/v1/page/getLinks.php')
                .then(function (response) {
                    if (response.data.message === "OK") {
                        for (var i = 0, len = Object.keys(response.data.pages).length; i < len; ++i) {
                            let title = String(response.data.pages[i].title)
                            let linkTo
                            if (title === 'Home') {
                                linkTo = '/'
                            } else {
                                linkTo = '/page/' + title.split(' ').join('-');
                            }
                            setLinks(links => links.concat(
                                <Nav.Link key={i} as={Link} to={linkTo}>{response.data.pages[i].title}</Nav.Link>
                            ))
                        }
                        setLinks(links => links.concat(
                            <>
                                <Nav.Link key={i} as={Link} to={'/contact'}>Contact</Nav.Link>
                                <Nav.Link key={i} as={Link} to={'/login'}>Login</Nav.Link>
                            </>
                        ))
                    }
                }).catch(function (error) {
                    alert("Error getting links" + error)
                });
        }, [])
        function stopLoading() {
            setTimeout(function () { setIsLoading(false) }, 1000)
        }
        let SliderSettings = {
            dots: false,
            autoplay: true,
            autoplaySpeed: 7000,
            lazyLoad: false,
            arrows: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            draggable: false
        };

        return (
            <>
                <div className="BeatLoader">
                    <BeatLoader
                        color={"#AE201F"}
                        loading={isLoading}
                    />
                </div>
                <div className={isLoading ? ('visibility-hidden') : ('fade-in')}>
                    <div className="carousel">
                        <div className="logo-block text-center">
                            <img src={'/api/temp/CARL_SIG.png'} />
                            <div className="logo-block-text">
                                <p className="char-spacing-2 selected-clients-p1">STUDIO LTD</p>
                                <div className="horiz-divider"></div>
                                <p className="banner-text">ILLUSTRATION<span className="dot"></span>CHARACTER DESIGN<span className="dot"></span>VISUALISATION</p>
                                <div className="horiz-divider"></div>
                            </div>
                        </div>
                        <Slider {...SliderSettings}>
                            {sliderImages}
                        </Slider >
                    </div>
                    <div className="selected-clients">
                        <Container>
                            <Row className="vertical-center text-center">
                                <Col>
                                    <div className="pt-5 pb-4">
                                        <p className="selected-clients-p1">SELECTED</p>
                                        <p className="selected-clients-p2">CLIENTS</p>
                                        <div className="horiz-divider"></div>
                                    </div>
                                    <img src="/api/temp/logos.png" className="pb-5" />
                                </Col>
                            </Row>

                        </Container>
                    </div>
                    <div className="selected-work">
                        <div className="container-fluid">
                            <div className="row align-items-center h-25">
                                <div className="col mx-auto text-center p-5">
                                    <p className="selected-clients-p1">SELECTED</p>
                                    <p className="selected-clients-p2">WORK</p>
                                    <div className="horiz-divider"></div>
                                </div>
                            </div>
                            <Row>
                                <Col>
                                    <div className="gallery-container">
                                        <Gallery2 photos={selectedWork} direction='row' targetRowHeight={galleryHeight} renderImage={imageRenderer} />
                                        <ModalGateway>
                                            {viewerIsOpen ? (
                                                <Modal onClose={closeLightbox} allowFullscreen={false}>
                                                    <Carousel
                                                        currentIndex={currentImage}
                                                        showNavigationOnTouchDevice={true}
                                                        views={selectedWork.map(x => ({
                                                            ...x,
                                                            srcset: x.srcSet,
                                                            caption: x.alt
                                                        }))}
                                                        components={{ Header: CarouselHeader }}
                                                    />
                                                </Modal>
                                            ) : null}
                                        </ModalGateway>
                                    </div>
                                </Col>
                            </Row>
                            <div className="row h-25 p-5">

                            </div>
                        </div>
                    </div>
                    <div className="footer d-flex align-items-center">
                        <Container fluid>
                            <Row>
                                <Col md={2} className="text-center">
                                    <img src="/api/temp/CARL_SIG.png" className="w-100 p-2" />
                                    <p className="char-spacing-2 selected-clients-p1">STUDIO LTD</p>
                                    <div className="horiz-divider"></div>
                                </Col>
                                <Col md={8} className="middle-footer">
                                    <ul className="p-2">
                                        {links}
                                    </ul>
                                </Col>
                                <Col md={2}>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </>
        )
    }
    function Page(props) {
        const [error, setError] = useState(null);
        const [selectedWork, setSelectedWork] = useState([]);
        const [title, setTitle] = useState(null)
        const [description, setDescription] = useState(null)
        const [isLoading, setIsLoading] = useState(true);
        const [links, setLinks] = useState([])
        const [currentImage, setCurrentImage] = useState(0);
        const [viewerIsOpen, setViewerIsOpen] = useState(false);
        const [galleryHeight, setGalleryHeight] = useState(250);
        function setGalHeight() {
            const { innerWidth: width, innerHeight: height } = window;
            if (width < 500) {
                setGalleryHeight(50);
            } else {
                setGalleryHeight(250)
            }
        }
        const openLightbox = useCallback((event, { photo, index }) => {
            setCurrentImage(index);
            setViewerIsOpen(true);
        }, []);

        const closeLightbox = () => {
            setCurrentImage(0);
            setViewerIsOpen(false);
        };
        const CarouselHeader = () => {
            return (
                <div className="header-container">
                    <div role="button" id="close1" onClick={closeLightbox}>
                        <div className="close2">
                            <div className="close3"></div>
                        </div>
                    </div>
                </div>
            )
        }
        const imageRenderer = useCallback(
            ({ index, left, top, key, photo }) => (
                <div className="image-overlay-container" style={{ margin: '2px' }}>
                    <LazyLoadImage
                        alt={photo.alt}
                        effect="blur"
                        className="image-hover"
                        width={photo.width}
                        height={photo.height}
                        placeholderSrc={photo.srcSet.lazyLoadPlaceholder}
                        src={photo.srcSet.thumbnail}
                        style={{ top: top, left: left }}
                    />
                    <div className="overlay text-center" onClick={(e) => { openLightbox(e, { photo, index }) }}>
                        <p>{photo.alt}</p>
                        <img alt="Eye SVG" src='/api/temp/eye.svg' />
                    </div>
                </div>
            )
        );

        useEffect(() => {
            setGalHeight()
            axios.get('/api/v1/page/get.php?page=' + props.id)
                .then(function (response) {
                    if (response.data.message === "OK") {
                        setTitle(response.data.page.title)
                        setDescription(response.data.page.description)
                        if (response.data.page.images !== undefined) {
                            let image = response.data.page.images
                            setSelectedWork(image)
                        } else {
                            setSelectedWork(null)
                        }
                    }
                }).catch(function (error) {
                    setError(error.message)
                });
            axios.get('/api/v1/page/getLinks.php')
                .then(function (response) {
                    if (response.data.message === "OK") {
                        for (var i = 0, len = Object.keys(response.data.pages).length; i < len; ++i) {
                            let title = String(response.data.pages[i].title)
                            let linkTo
                            if (title === 'Home') {
                                linkTo = '/'
                            } else {
                                linkTo = '/page/' + title.split(' ').join('-');
                            }
                            setLinks(links => links.concat(
                                <Nav.Link key={i} as={Link} to={linkTo}>{response.data.pages[i].title}</Nav.Link>
                            ))
                        }
                        setLinks(links => links.concat(
                            <>
                                <Nav.Link key={i} as={Link} to={'/contact'}>Contact</Nav.Link>
                                <Nav.Link key={i} as={Link} to={'/login'}>Login</Nav.Link>
                            </>
                        ))
                        setIsLoading(false);
                    }
                }).catch(function (error) {
                    alert("Error getting links" + error)
                });
        }, [])
        if (isLoading) {
            return (
                <div className="BeatLoader">
                    <BeatLoader
                        color={"#AE201F"}
                        loading={isLoading}
                    />
                </div>
            )
        } else {
            return (
                <>
                    <div className="fade-in">
                        <div className="page pt-5">
                            <Container className="h-100 relative-position">
                                <Row className="align-items-center text-center">
                                    <Col>
                                        <p className="selected-clients-p2">{title}</p>
                                        <div className="horiz-divider"></div>
                                    </Col>
                                </Row>
                                <Row className="align-items-center text-center">
                                    <Col className="my-auto">
                                        <p>{description}</p>
                                    </Col>
                                </Row>

                            </Container>
                        </div>
                        <div className="selected-work">
                            <div className="container-fluid">
                                <div className="row align-items-center h-25">
                                    <div className="col mx-auto text-center p-5">
                                        <p className="selected-clients-p1">SELECTED</p>
                                        <p className="selected-clients-p2">WORK</p>
                                        <div className="horiz-divider"></div>
                                    </div>
                                </div>
                                <Row>
                                    <Col>
                                        {selectedWork ? (
                                            <div className="gallery-container">
                                                <Gallery2 photos={selectedWork} direction='row' targetRowHeight={galleryHeight + 50} renderImage={imageRenderer} />
                                                <ModalGateway>
                                                    {viewerIsOpen ? (
                                                        <Modal onClose={closeLightbox} allowFullscreen={false}>
                                                            <Carousel
                                                                showNavigationOnTouchDevice={true}
                                                                currentIndex={currentImage}
                                                                views={selectedWork.map(x => ({
                                                                    ...x,
                                                                    srcset: x.srcSet,
                                                                    caption: x.alt
                                                                }))}
                                                                components={{ Header: CarouselHeader }}
                                                            />
                                                        </Modal>
                                                    ) : null}
                                                </ModalGateway>
                                            </div>


                                        ) : (null)}
                                    </Col>
                                </Row>
                                <div className="row h-25 p-5">

                                </div>
                            </div>
                        </div>
                        <div className="footer d-flex align-items-center">
                            <Container fluid>
                                <Row>
                                    <Col md={2} className="text-center">
                                        <img src="/api/temp/CARL_SIG.png" className="w-100 p-2" />
                                        <p className="char-spacing-2 selected-clients-p1">STUDIO LTD</p>
                                        <div className="horiz-divider"></div>
                                    </Col>
                                    <Col md={8} className="middle-footer">
                                        <ul className="p-2">
                                            {links}
                                        </ul>
                                    </Col>
                                    <Col md={2}>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </>
            )
        }
    }
    function Contact() {
        const [error, setError] = useState(null);
        const [sending, setSending] = useState("Submit")
        const [firstName, setFirstName] = useState("");
        const [lastName, setLastName] = useState("");
        const [email, setEmail] = useState("");
        const [message, setMessage] = useState("");
        const [isLoading, setIsLoading] = useState(true)
        const [links, setLinks] = useState([])
        useEffect(() => {
            axios.get('/api/v1/page/getLinks.php')
                .then(function (response) {
                    if (response.data.message === "OK") {
                        for (var i = 0, len = Object.keys(response.data.pages).length; i < len; ++i) {
                            let title = String(response.data.pages[i].title)
                            let linkTo
                            if (title === 'Home') {
                                linkTo = '/'
                            } else {
                                linkTo = '/page/' + title.split(' ').join('-');
                            }
                            setLinks(links => links.concat(
                                <Nav.Link key={i} as={Link} to={linkTo}>{response.data.pages[i].title}</Nav.Link>
                            ))
                        }
                        setLinks(links => links.concat(
                            <>
                                <Nav.Link key={i} as={Link} to={'/contact'}>Contact</Nav.Link>
                                <Nav.Link key={i} as={Link} to={'/login'}>Login</Nav.Link>
                            </>
                        ))
                        setIsLoading(false);
                    }
                }).catch(function (error) {
                    alert("Error getting links" + error)
                });
            const script = document.createElement("script")
            script.src = "https://www.google.com/recaptcha/api.js?render=6LeftdoZAAAAAJeKWL77ZB8fWocKjmJcP3wtfxP-"
            document.body.appendChild(script)
        }, [])
        function submitForm(e) {
            setSending(false)
            e.preventDefault();
            window.grecaptcha.ready(function () {
                window.grecaptcha.execute('6LeftdoZAAAAAJeKWL77ZB8fWocKjmJcP3wtfxP-', { action: 'submit' }).then(function (token) {
                    axios.post('/api/v1/contact.php', {
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        message: message,
                        token: token
                    }).then(function (response) {
                        if (response.data.message === 'OK') {
                            setError(null)
                            setSending("Sent")
                        } else {
                            setError(response.data.message)
                        }
                    }).catch(function (error) {
                        setError(error.message)
                    });
                })
            })
        }
        function RenderError() {
            if (error === null) {
                return null
            } else {
                return <Alert className="m-2" variant="warning">{error}</Alert>
            }
        }
        if (isLoading) {
            return (
                <div className="BeatLoader">
                    <BeatLoader
                        color={"#AE201F"}
                        loading={isLoading}
                    />
                </div>
            )
        } else {
            return (
                <>
                    <div className="fade-in">
                        <div className="page pt-5">
                            <Container className="h-100 relative-position">
                                <Row className="align-items-center text-center">
                                    <Col>
                                        <p className="selected-clients-p2">Contact</p>
                                        <div className="horiz-divider"></div>
                                    </Col>
                                </Row>
                                <Row className="align-items-center text-center p-5">
                                    <Col className="my-auto">

                                    </Col>
                                </Row>

                            </Container>
                        </div>
                        <div className="selected-work">
                            <div className="row h-25 p-5">

                            </div>
                            <Row>
                                <Col className="mx-auto">
                                    <Container className="contact-form">
                                        <RenderError />
                                        <Form onSubmit={submitForm}>
                                            <Form.Group>
                                                <Row>
                                                    <Col>
                                                        <Form.Label>First Name</Form.Label>
                                                        <Form.Control placeholder="First Name" value={firstName} onChange={(e) => setFirstName(e.currentTarget.value)} max-length="20" required />
                                                    </Col>
                                                    <Col>
                                                        <Form.Label>Last Name</Form.Label>
                                                        <Form.Control placeholder="Last Name" value={lastName} onChange={(e) => setLastName(e.currentTarget.value)} max-length="20" required />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group controlId="exampleForm.ControlInput1">
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control type="email" placeholder="name@example.com" value={email} onChange={(e) => setEmail(e.currentTarget.value)} max-length="100" required />
                                            </Form.Group>
                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>Message</Form.Label>
                                                <Form.Control as="textarea" rows={5} placeholder="Message" value={message} onChange={(e) => setMessage(e.currentTarget.value)} max-length="2000" required />
                                            </Form.Group>
                                            <Form.Label>This site is protected with reCaptcha v3</Form.Label>
                                            <Button variant="primary" className="float-right" type="submit">{sending ? (sending) : (<BeatLoader color={"#FFFFFF"} loading={isLoading}/>)}</Button>
                                        </Form>
                                    </Container>
                                </Col>
                            </Row>
                            <div className="row h-25 p-5">

                            </div>
                        </div>
                    </div>
                    <div className="footer d-flex align-items-center">
                        <Container fluid>
                            <Row>
                                <Col md={2} className="text-center">
                                    <img src="/api/temp/CARL_SIG.png" className="w-100 p-2" />
                                    <p className="char-spacing-2 selected-clients-p1">STUDIO LTD</p>
                                    <div className="horiz-divider"></div>
                                </Col>
                                <Col md={8} className="middle-footer">
                                    <ul className="p-2">
                                        {links}
                                    </ul>
                                </Col>
                                <Col md={2}>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </>
            )
        }
    }
}