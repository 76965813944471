import React, { useState, useEffect } from 'react';
import NavBar from './Navbar'
import { Table, Container, Button, Alert, Modal } from 'react-bootstrap'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'


export default function Users() {
    const [users, setUsers] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteEmail, setDeleteEmail] = useState(null);
    const [error, setError] = useState(null)
    const [userUpdated, setUserUpdated] = useState(false)


    const history = useHistory();
    function RenderError() {
        if (error === null) {
            return null
        } else {
            return <Alert className="m-2" variant="warning">{error}</Alert>
        }
    }
    function DeleteModal(event) {
        let user = JSON.parse(event.currentTarget.value)
        setDeleteId(user.id)
        setDeleteEmail(user.email)
        handleShow()
    }
    useEffect(() => {
        axios.get('/api/v1/user/get.php')
            .then(function (response) {
                if (response.data.message === "OK") {
                    setUsers([]);
                    for (var i = 0, len = Object.keys(response.data.users).length; i < len; ++i) {
                        let permission
                        if (response.data.users[i].permission == 1) {
                            permission = "User";
                        } else if (response.data.users[i].permission == 2) {
                            permission = "Admin"
                        }
                        let user = JSON.stringify(response.data.users[i])
                        setUsers(users => users.concat(<tr key={response.data.users[i].id}><td>{response.data.users[i].id}</td><td>{response.data.users[i].first_name}</td><td>{response.data.users[i].last_name}</td><td>{response.data.users[i].email}</td><td>{permission}</td><td><Link to={'/users/edit/' + response.data.users[i].id}><Button variant="secondary">Edit</Button></Link></td><td><Button variant="danger" onClick={DeleteModal} value={user}>Delete</Button></td></tr>))
                    }
                } else if (response.data.message === 'You must be signed in to perform that action') {
                    history.push('/login')
                } else {
                    RenderError(response.data.message)
                }
            })
            .catch(function (error) {
                RenderError(error.message)
            });
    }, [userUpdated])

    function DeleteUser() {
        axios.post('/api/v1/user/delete.php', {
            id: deleteId
        }).then(function (response) {
            if (response.data.message === 'OK') {
                handleClose()
                setUserUpdated(!userUpdated);
            } else if (response.data.message === 'You must be signed in to perform that action') {
                history.push('/login')
            } else {
                setError(response.data.message)
            }
        })
    }
    return (
        <>
            <NavBar loginPage={false} page={"users"}></NavBar>
            <Container className="top-margin">
                <Link to='/'><Button variant="outline-secondary">Back Home</Button></Link>
                <Link to='/users/add'><Button variant="outline-primary" className="float-right">Add User</Button></Link>
                <RenderError></RenderError>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email Address</th>
                            <th>Permission</th>
                            <th>Edit User</th>
                            <th>Delete User</th>
                        </tr>
                    </thead>
                    <tbody>{users}</tbody>
                </Table>
            </Container>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete {deleteEmail}?</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete the user?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
          </Button>
                    <Button variant="danger" onClick={DeleteUser}>
                        Delete
          </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
