import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { Nav } from 'react-bootstrap';
import axios from 'axios';
export default function Footer(props) {
    const [links, setLinks] = useState([])
    useEffect(() => {
        axios.get('/api/v1/page/getLinks.php')
            .then(function (response) {
                if (response.data.message === "OK") {
                    for (var i = 0, len = Object.keys(response.data.pages).length; i < len; ++i) {
                        let title = String(response.data.pages[i].title)
                        let link
                        if (title === 'Home') {
                            link = '/'
                        } else {
                            link = '/page/' + title.split(' ').join('-');
                        }
                        setLinks(links => links.concat(
                            <Nav.Link key={i} as={Link} to={link}>{response.data.pages[i].title}</Nav.Link>
                        ))
                    }
                    setLinks(links => links.concat(
                        <>
                            <Nav.Link key={i} as={Link} to={'/contact'}>Contact</Nav.Link>
                            <Nav.Link key={i} as={Link} to={'/login'}>Login</Nav.Link>
                        </>
                    ))
                }
            }).catch(function (error) {
                alert("Error getting links" + error)
            });
    }, [])
    return (
        <>
            <ul className="p-2">
                {links}
            </ul>
        </>
    )
}