import React, {useState} from 'react';
import { useDropzone } from 'react-dropzone'
import NavBar from './Navbar';
import Masonry from 'react-masonry-component';
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import { Form, Button, Container, Alert, Row, Col, Card, ProgressBar } from 'react-bootstrap';

export default function AddPage() {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState(null);
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const history = useHistory();

    function AddPage(e) {
        e.preventDefault();
        axios.post('/api/v1/page/new.php', {
            title: title,
            description: description
        }).then(function (response) {
            if (response.data.message === "OK") {
                UploadImages(response.data.id);
            }
        })
    }
    function UploadImages(id) {
        let fd = new FormData();
        fd.append('id', id)
        fd.append('type', 'page')
        const captions = files.map(file => (
            file.caption
        ));
        fd.append('captions', JSON.stringify(captions))
        files.forEach((file) => {
            fd.append('File[]', file);
        });
        var config = {
            onUploadProgress: function (progressEvent) {
                setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
            }
        };
        if (files && files.length > 0) {
            console.log(captions);
            axios.post('/api/v1/image/imageUpload.php', fd, config)
                .then(function (response) {
                    if (response.data.message === 'OK') {
                        setError(null)
                        history.push('/pages')
                    } else {
                        setError(response.data.message)
                    }
                }).catch(function (error) {
                    setError(error.message)
                })
        } else {
            setError(null)
            history.push('/pages')
        }
    }
    function RenderError() {
        if (error === null) {
            return null
        } else {
            return <Alert className="m-2" variant="warning">{error}</Alert>
        }
    }
    function Previews(props) {
        let tempFiles = files.slice(-1)[0]
        let index = 0;
        if (tempFiles !== undefined) {
            index = tempFiles.id + 1
        }
        const { getRootProps, getInputProps } = useDropzone({
            accept: 'image/*',
            onDrop: acceptedFiles => {
                setFiles(files => files.concat(acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }, { id: index++ }))));
                for (let i = tempFiles + 1; i < files.length; i++) {
                    URL.revokeObjectURL(files[i].preview)
                }
            }
        });
        function DeleteFile(event) {
            let id = event.currentTarget.value
            id = parseInt(id)
            alert(id)
            setFiles(files => files.filter(file => file.id !== id))
        }
        const thumbs = files.map(file => (
            <Card style={{ width: '18rem' }} key={file.id} className="addVaultCards m-2">
                <Card.Img variant="top" src={file.preview} />
                <Card.Body>
                    <Form.Group>
                        <Form.Control type="text" value={file.caption} onChange={(e) => file.caption = e.target.value} placeholder="Image Caption" />
                    </Form.Group>
                    <Button variant="danger" onClick={DeleteFile} value={file.id}>Remove</Button>
                </Card.Body>
            </Card>
        ));
        function NewFilesTitle() {
            if (Array.isArray(files) && files.length === 0) {
                return null
            } else {
                return (<h3>New Files:</h3>)
            }
        }

        return (
            <section className="container-fluid text-center">
                <Container>
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                </Container>
                {NewFilesTitle}
                <Masonry
                    className={'my-gallery-class new-images text-center'} // default ''
                    elementType={'ul'} // default 'div'
                    options={masonryOptions} // default {}
                    disableImagesLoaded={false} // default false
                    updateOnEachImageLoad={false}
                // default false and works only if disableImagesLoaded is false
                >
                    {thumbs}
                </Masonry>
            </section>
        );
    }
    const masonryOptions = {
        transitionDuration: 0,
        fitWidth: true
    };
    const Progress = () => {
        if (uploadProgress === 0) {
            return null
        } else {
            return <ProgressBar now={uploadProgress} />
        }
    }
    return (
        <>
            <NavBar loginPage={false} page={"editHomepage"}></NavBar>
            <Container fluid>
                <Row>
                    <Col>
                        <Form onSubmit={AddPage}>
                            <Container className="login-form">
                                <h2 className="text-center p-2">Add Page</h2>
                                <RenderError></RenderError>
                                <Progress />
                                <Form.Group>
                                    <Form.Label>Page Title</Form.Label>
                                    <Form.Control type="text" placeholder="Page Title" maxLength="50" minLength="1" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Page Description</Form.Label>
                                    <Form.Control as="textarea" placeholder="Page Description" value={description} onChange={(e) => setDescription(e.target.value)} maxLength="500" rows="3" />
                                </Form.Group>

                                <p>Upload images below:</p>
                            </Container>
                            <Previews />
                            <Masonry
                                className={'my-gallery-class'} // default ''
                                elementType={'ul'} // default 'div'
                                options={masonryOptions} // default {}
                                disableImagesLoaded={false} // default false
                                updateOnEachImageLoad={false}
                            // default false and works only if disableImagesLoaded is false
                            >

                            </Masonry>
                            <Container>
                                <div className="p-2">
                                    <Button variant="primary" type="submit" value="Submit" className="float-right">Add Page</Button>
                                    <Link to="/pages"><Button variant="secondary" type="button" className="float-right mr-2">Cancel</Button></Link>
                                </div>
                            </Container>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}