import React, {useState, useEffect, useCallback} from 'react';
import NavBar from './Navbar';
import { useParams } from 'react-router'
import axios from 'axios';
import Gallery2 from 'react-photo-gallery';
import Carousel, { Modal, ModalGateway } from "react-images";
import BeatLoader from "react-spinners/BeatLoader";
import Footer from "./Footer"
import { Container, Alert, Row, Col } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function DisplayVault() {
    const [error, setError] = useState(null);
    const [selectedWork, setSelectedWork] = useState([]);
    const [title, setTitle] = useState("Title")
    const [description, setDescription] = useState("Description")
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();
    const [currentImage, setCurrentImage] = useState(0);
        const [viewerIsOpen, setViewerIsOpen] = useState(false);
        const [galleryHeight, setGalleryHeight] = useState(250);
        function setGalHeight() {
            const { innerWidth: width, innerHeight: height } = window;
            if (width < 500) {
                setGalleryHeight(50);
            } else {
                setGalleryHeight(250)
            }
        }
        const openLightbox = useCallback((event, { photo, index }) => {
            setCurrentImage(index);
            setViewerIsOpen(true);
        }, []);

        const closeLightbox = () => {
            setCurrentImage(0);
            setViewerIsOpen(false);
        };
        const imageRenderer = useCallback(
            ({ index, left, top, key, photo }) => (
                <div className="image-overlay-container" style={{ margin: '2px' }}>
                    <LazyLoadImage
                        alt={photo.alt}
                        effect="blur"
                        className="image-hover"
                        width={photo.width}
                        height={photo.height}
                        placeholderSrc={photo.srcSet.lazyLoadPlaceholder}
                        src={photo.srcSet.thumbnail}
                        style={{ top: top, left: left }}
                    />
                    <div className="overlay text-center" onClick={(e) => { openLightbox(e, { photo, index }) }}>
                        <p>{photo.alt}</p>
                        <img alt="Eye SVG" src='/api/temp/eye.svg' />
                    </div>
                </div>
            )
        );

    useEffect(() => {
        setGalHeight()
        axios.get('/api/v1/vault/get.php?token=' + params.token + '&images')
            .then(function (response) {
                if (response.data.message === "OK") {
                    setTitle(response.data.vault[0].title)
                    setDescription(response.data.vault[0].description)
                    if (response.data.images !== undefined) {
                        let image = response.data.images
                        setSelectedWork(image)
                    } else {
                        setSelectedWork(null)
                    }
                    setIsLoading(false)
                } else if (response.data.message === "Invalid token") {
                    setError("Invalid Vault Token")
                    setIsLoading(false)
                }
            }).catch(function (error) {
                setError(error.message)
            });
    }, [])
    function RenderError() {
        if (error === null) {
            return null
        } else {
            return <Alert className="m-2" variant="warning">{error}</Alert>
        }
    }
    if (isLoading) {
        return (
            <div className="BeatLoader">
                <BeatLoader
                    color={"#AE201F"}
                    loading={isLoading}
                />
            </div>
        )
    } else {
        return (
            <>
                <NavBar loginPage={false} page="home"></NavBar>
                <div className="fade-in">
                    <div className="page pt-5">
                        <Container className="h-100 relative-position">
                            <Row className="align-items-center text-center">
                                <Col>
                                    <p className="selected-clients-p2">{title}</p>
                                    <div className="horiz-divider"></div>
                                </Col>
                            </Row>
                            <Row className="align-items-center text-center p-5">
                                <Col className="my-auto">
                                    <p>{description}</p>
                                </Col>
                            </Row>

                        </Container>
                    </div>
                    <div className="selected-work">
                        <div className="container-fluid">
                            <div className="row align-items-center h-25">
                                <div className="col mx-auto text-center p-5">
                                    <p className="selected-clients-p1">SELECTED</p>
                                    <p className="selected-clients-p2">WORK</p>
                                    <div className="horiz-divider"></div>
                                </div>
                            </div>
                            <Row>
                                <Col>
                                    <RenderError />
                                    <div className="gallery-container">
                                        <Gallery2 photos={selectedWork} direction='row' targetRowHeight={galleryHeight} renderImage={imageRenderer} />
                                        <ModalGateway>
                                            {viewerIsOpen ? (
                                                <Modal onClose={closeLightbox} allowFullscreen={false}>
                                                    <Carousel
                                                        currentIndex={currentImage}
                                                        showNavigationOnTouchDevice={true}
                                                        views={selectedWork.map(x => ({
                                                            ...x,
                                                            srcset: x.srcSet,
                                                            caption: x.alt
                                                        }))}
                                                    />
                                                </Modal>
                                            ) : null}
                                        </ModalGateway>
                                    </div>
                                </Col>
                            </Row>
                            <div className="row h-25 p-5">

                            </div>
                        </div>
                    </div>
                    <div className="footer d-flex align-items-center">
                        <Container fluid>
                            <Row>
                                <Col md={2} className="text-center">
                                    <img src="/api/temp/CARL_SIG.png" className="w-100 p-2" />
                                    <p className="char-spacing-2">STUDIO LTD</p>
                                    <div className="horiz-divider"></div>
                                </Col>
                                <Col md={8} className="middle-footer">
                                    <Footer />
                                </Col>
                                <Col md={2}>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </>
        )
    }
}