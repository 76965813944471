import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone'
import NavBar from './Navbar';
import Masonry from 'react-masonry-component';
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import { Form, Button, Container, Alert, Row, Col, Card, ProgressBar } from 'react-bootstrap';

export default function AddVault() {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState(1);
    const [error, setError] = useState(null);
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);

    const history = useHistory();
    function AddVault(e) {
        e.preventDefault();
        let fd = new FormData();
        fd.append('type', 'vault')
        const captions = files.map(file => (
            file.caption
        ));
        fd.append('captions', JSON.stringify(captions))
        files.forEach((file) => {
            fd.append('File[]', file);
        });
        axios.post('/api/v1/vault/new.php', {
            title: title,
            description: description
        })
            .then(function (response) {
                if (response.data.message === 'OK') {
                    fd.append('id', response.data.vault_id)
                    var config = {
                        onUploadProgress: function (progressEvent) {
                            setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                        }
                    };
                    axios.post('/api/v1/image/imageUpload.php', fd, config)
                        .then(function (response) {
                            if (response.data.message === 'OK') {
                                setError(null)
                                history.push('/vaults')
                            } else {
                                setError(response.data.message)
                            }
                        }).catch(function (error) {
                            setError(error.message)
                        })
                } else if (response.data.message === 'You must be signed in to perform that action') {
                    history.push('/login')
                } else {
                    setError(response.data.message)
                }
            }).catch(function (error) {
                setError(error.message)
            });
    }
    function RenderError() {
        if (error === null) {
            return null
        } else {
            return <Alert className="m-2" variant="warning">{error}</Alert>
        }
    }
    function Previews(props) {
        let tempFiles = files.slice(-1)[0]
        let index = 0;
        if (tempFiles !== undefined) {
            index = tempFiles.id + 1
        }
        const { getRootProps, getInputProps } = useDropzone({
            accept: 'image/*',
            onDrop: acceptedFiles => {
                setFiles(files => files.concat(acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }, { id: index++ }))));
                for (let i = tempFiles + 1; i < files.length; i++) {
                    URL.revokeObjectURL(files[i].preview)
                }
            }
        });
        function DeleteFile(event) {
            let id = event.currentTarget.value
            id = parseInt(id)
            setFiles(files => files.filter(file => file.id !== id))
        }
        const thumbs = files.map(file => (
            <Card style={{ width: '18rem' }} key={file.id} className="addVaultCards m-2">
                <Card.Img variant="top" src={file.preview} />
                <Card.Body>
                    <Form.Group>
                        <Form.Control type="text" value={file.caption} onChange={(e) => file.caption = e.target.value} placeholder="Image Caption" />
                    </Form.Group>
                    <Button variant="danger" onClick={DeleteFile} value={file.id}>Remove</Button>
                </Card.Body>
            </Card>

        ));
        return (
            <section className="container-fluid">
                <Container>
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                </Container>
                <Masonry
                    className={'my-gallery-class'} // default ''
                    elementType={'ul'} // default 'div'
                    options={masonryOptions} // default {}
                    disableImagesLoaded={false} // default false
                    updateOnEachImageLoad={false}
                // default false and works only if disableImagesLoaded is false
                >
                    {thumbs}
                </Masonry>
            </section>
        );
    }
    const masonryOptions = {
        transitionDuration: 0,
        fitWidth: true
    };
    const Progress = () => {
        if (uploadProgress === 0) {
            return null
        } else {
            return <ProgressBar now={uploadProgress} />
        }
    }
    return (
        <>
            <NavBar loginPage={false} page={"vaults"}></NavBar>
            <Container fluid>
                <Row>
                    <Col>
                        <Form onSubmit={AddVault}>
                            <Container className="login-form">
                                <h2 className="text-center p-2">Add a new vault</h2>
                                <RenderError></RenderError>
                                <Progress />
                                <Form.Group>
                                    <Form.Label>Vault Title</Form.Label>
                                    <Form.Control type="text" placeholder="Title" maxLength="50" minLength="1" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Vault Description</Form.Label>
                                    <Form.Control as="textarea" placeholder="Vault Description" onChange={(e) => setDescription(e.target.value)} maxLength="500" rows="3" />
                                </Form.Group>
                                <p>Upload images below:</p>
                            </Container>
                            <Previews />
                            <Container>
                                <div className="p-2">
                                    <Button variant="primary" type="submit" value="Submit" className="float-right">Add Vault</Button>
                                    <Link to="/vaults"><Button variant="secondary" type="button" className="float-right mr-2">Cancel</Button></Link>
                                </div>
                            </Container>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    )
}