import React from 'react';
import { Route, BrowserRouter as Router } from "react-router-dom";
import Cookies from 'js-cookie';
import Login from './components/Login';
import Account from './components/Account';
import Users from './components/Users';
import AddUser from './components/AddUser';
import EditUser from './components/EditUser';
import ManageVaults from './components/ManageVaults';
import AddVault from './components/AddVault';
import EditVault from './components/EditVault';
import DisplayVault from './components/DisplayVault';
import Pages from './components/Pages';
import AddPage from './components/AddPage';
import EditPage from './components/EditPage';
import Page from './components/Page';
export default function App() {
    document.addEventListener('contextmenu', event => event.preventDefault());
    let isAuthenticated
    let cookie = Cookies.get('Permission')
    function ProtectedRoute(props) {
        if (parseInt(cookie) === 1 || parseInt(cookie) === 2) {
            isAuthenticated = true
        } else {
            isAuthenticated = false
        }    
        if (isAuthenticated) {
            return (<Route exact path={props.path} component={props.component} />)
        } else {
            return (<Route exact path={props.path} component={Login} />)
        }
    }
    return (
        <>
            <Router>
                <Route exact path={["/", "/page/:id", "/contact"]} component={Page} />
                <ProtectedRoute path='/login' component={Login} />
                <ProtectedRoute path="/account" component={Account} />
                <ProtectedRoute path="/users" component={Users} />
                <ProtectedRoute path="/pages" component={Pages} />
                <ProtectedRoute path="/pages/add" component={AddPage} />
                <ProtectedRoute exact path="/pages/edit/:id" component={EditPage}/>
                <ProtectedRoute exact path="/users/add" component={AddUser} />
                <ProtectedRoute exact path="/users/edit/:id" component={EditUser}/>
                <ProtectedRoute exact path="/vaults" component={ManageVaults}/>
                <ProtectedRoute exact path="/vaults/add" component={AddVault}/>
                <ProtectedRoute exact path="/vaults/edit/:id" component={EditVault}/>
                <Route exact path="/vault/token/:token" component={DisplayVault}/>
            </Router>
        </>
    )
}
