import React, { useState, useEffect } from 'react';
import NavBar from './Navbar'
import { Table, Container, Button, Alert, Modal, Form, Row, Col } from 'react-bootstrap'
import { useHistory, Link } from 'react-router-dom'
import axios from 'axios'

export default function ManageVaults() {
    const [vaults, setVaults] = useState([])
    const [show, setShow] = useState(false);
    const [vaultId2, setVaultId2] = useState(null);
    const [vaultTitle, setVaultTitle] = useState(null);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const closeToken = () => setTokenShow(false);
    const openToken = () => setTokenShow(true);
    const [tokenShow, setTokenShow] = useState(false);

    const [tokens, setTokens] = useState([]);
    const [deleteId, setDeleteId] = useState(null);
    const [deleteTitle, setDeleteTitle] = useState(null);
    const [error, setError] = useState(null)
    const [vaultUpdated, setVaultUpdated] = useState(false)
    const [expireAfter, setExpireAfter] = useState(1);
    const history = useHistory();
    function RenderError() {
        if (error === null) {
            return null
        } else {
            return <Alert className="m-2" variant="warning">{error}</Alert>
        }
    }
    function DeleteModal(event) {
        let vault = JSON.parse(event.currentTarget.value)
        setDeleteId(vault.id)
        setDeleteTitle(vault.title)
        handleShow()
    }
    useEffect(() => {
        axios.get('/api/v1/vault/get.php?id=all')
            .then(function (response) {
                if (response.data.message === "OK") {
                    setVaults([])
                    if (response.data.vaults !== undefined) {
                        for (var i = 0, len = Object.keys(response.data.vaults).length; i < len; ++i) {
                            let vault = JSON.stringify(response.data.vaults[i])
                            setVaults(vaults => vaults.concat(<tr key={response.data.vaults[i].id}><td>{response.data.vaults[i].title}</td><td>{response.data.vaults[i].description}</td><td><Button variant="secondary" onClick={getTokens} value={vault}>Access Tokens</Button></td><td><Link to={'/vaults/edit/' + response.data.vaults[i].id}><Button variant="secondary">Edit Vault</Button></Link></td><td><Button variant="danger" onClick={DeleteModal} value={vault}>Delete</Button></td></tr>))
                        }
                    }
                } else if (response.data.message === 'You must be signed in to perform that action') {
                    history.push('/login')
                } else {
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                setError(error.message)
            });
    }, [vaultUpdated])
    function generateLink(e) {
        let link = e.currentTarget.value
        navigator.clipboard.writeText(link)
        alert("Link copied")
    }
    function revokeToken(e) {
        let id = JSON.parse(e.currentTarget.value).access_token
        axios.post('/api/v1/token/revoke.php', {
            token: id
        }).then(function (response) {
            if (response.data.message === "OK") {
                GetTokensFromServer()
            } else {
                setError(response.data.message)
            }
        }).catch(function (error) {
            setError(error.message)
        })
    }
    function GetTokensFromServer() {
        axios.get('/api/v1/token/get.php?id=' + vaultId2)
            .then(function (response) {
                if (response.data.message === 'OK') {
                    setError(null)
                    setTokens([])
                    if (response.data.tokens) {
                        for (var i = 0, len = Object.keys(response.data.tokens).length; i < len; ++i) {
                            let token = JSON.stringify(response.data.tokens[i])
                            let link = window.location.hostname + '/vault/token/' + response.data.tokens[i].access_token
                            setTokens(tokens => tokens.concat(<tr key={response.data.tokens[i].access_token}><td>{response.data.tokens[i].access_token}</td><td>{response.data.tokens[i].valid_until}</td><td><Button variant="danger" onClick={revokeToken} value={token}>Revoke</Button></td><td><Button variant="primary" onClick={generateLink} value={link}>Copy Link</Button></td></tr>))
                        }
                    }
                } else {
                    setError(response.data.message)
                }
            }).catch(function (error) {
            })
    }
    function getTokens(event) {
        let vault = JSON.parse(event.currentTarget.value)
        setVaultId2(vault.id)
        axios.get('/api/v1/token/get.php?id=' + vault.id)
            .then(function (response) {
                if (response.data.message === 'OK') {
                    if (response.data.tokens !== undefined) {
                        setError(null)
                        setTokens([])
                        for (var i = 0, len = Object.keys(response.data.tokens).length; i < len; ++i) {
                            let token = JSON.stringify(response.data.tokens[i])
                            let link = window.location.hostname + '/vault/token/' + response.data.tokens[i].access_token
                            setTokens(tokens => tokens.concat(<tr key={response.data.tokens[i].access_token}><td>{response.data.tokens[i].access_token}</td><td>{response.data.tokens[i].valid_until}</td><td><Button variant="danger" onClick={revokeToken} value={token}>Revoke</Button></td><td><Button variant="primary" onClick={generateLink} value={link}>Copy Link</Button></td></tr>))
                        }
                    }
                } else {
                    setError(response.data.message)
                }
            }).catch(function (error) {
                setError(error.message)
            })
        openToken()
    }
    function GenerateToken(e) {
        e.preventDefault()
        axios.post('/api/v1/token/new.php', {
            id: vaultId2,
            expires: expireAfter
        }).then(function (response) {
            if (response.data.message === 'OK') {
                GetTokensFromServer()
            }
        })
    }
    function DeleteVault() {
        axios.post('/api/v1/vault/delete.php', {
            id: deleteId
        }).then(function (response) {
            handleClose()
            if (response.data.message === 'OK') {
                setVaultUpdated(!vaultUpdated);
            } else if (response.data.message === 'You must be signed in to perform that action') {
                history.push('/login')
            } else {
                setError(response.data.message)
            }
        })
    }
    return (
        <>
            <NavBar loginPage={false} page={"vaults"}></NavBar>
            <Container className="top-margin">
                <Link to='/'><Button variant="outline-secondary">Back Home</Button></Link>
                <Link to='/vaults/add'><Button variant="outline-primary" className="float-right">Add Vault</Button></Link>
                <RenderError></RenderError>
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th className="w-50">Description</th>
                            <th>Access Tokens</th>
                            <th>Edit Vault</th>
                            <th>Delete Vault</th>
                        </tr>
                    </thead>
                    <tbody>{vaults}</tbody>
                </Table>
            </Container>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete {deleteTitle}?</Modal.Title>
                </Modal.Header>
                <Modal.Body><h2>Deleting the vault will also delete all images stored within!</h2></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
          </Button>
                    <Button variant="danger" onClick={DeleteVault}>
                        Delete
          </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={tokenShow} onHide={closeToken} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Access tokens for {vaultTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table responsive className="vault-table">
                        <thead>
                            <tr>
                                <th>Token</th>
                                <th>Expires</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{tokens}</tbody>
                    </Table>

                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Form onSubmit={GenerateToken}>
                            <Form.Group>
                                <Row>
                                    <Col>
                                        <Row>
                                            <Col lg="3" className="my-auto">
                                                Expire after
                                            </Col>
                                            <Col lg="3">
                                                <Form.Control type="text" placeholder="" maxLength="3" minLength="1" value={expireAfter} onChange={(e) => setExpireAfter(e.target.value)} required />
                                            </Col>
                                            <Col className="my-auto">
                                                day
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Button variant="primary" type="submit">Generate New Token</Button>
                            </Form.Group>
                        </Form>
                    </Container>
                    <Button variant="secondary" onClick={closeToken}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
