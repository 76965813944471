import React, { useState } from 'react';
import NavBar from './Navbar';
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios';
import { Form, Button, Container, Alert, Row, Col } from 'react-bootstrap';

export default function AddUser() {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [permission, setPermission] = useState(1);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(null);
    const history = useHistory();
    function AddUser(e) {
        e.preventDefault();
        if (password !== confirmPassword) {
            setError("Passwords Don't match")
            return;
        }
        axios.post('/api/v1/user/new.php', {
            firstName: firstName,
            lastName: lastName,
            email: email,
            permission: permission,
            password: password,
            confirmPassword: password
        }).then(function (response) {
            if (response.data.message === 'OK') {
                setError(null)
                history.push('/users')
            } else if (response.data.message === 'You must be signed in to perform that action') {
                history.push('/login')
            } else {
                setError(response.data.message)
            }
        }).catch(function (error) {
            setError(error.message)
        });
    }
    function RenderError() {
        if (error === null) {
            return null
        } else {
            return <Alert className="m-2" variant="warning">{error}</Alert>
        }
    }
    return (
        <>
            <NavBar loginPage={false} page={"users"}></NavBar>
            <Container>
                <Form className="login-form" onSubmit={AddUser}>
                    <h2 className="text-center p-2">Add a new user</h2>
                    <RenderError></RenderError>
                    <Form.Group>
                        <Row>
                            <Col>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" placeholder="First Name" maxLength="20" minLength="1" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                            </Col>
                            <Col>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="First Name" maxLength="20" minLength="1" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Email Address" maxLength="50" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Permission</Form.Label>
                        <Form.Control value={permission} onChange={(e) => setPermission(e.target.value)} as="select" required>
                            <option value="1" selected>User</option>
                            <option value="2">Admin</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" minLength="6" onChange={(e) => setPassword(e.target.value)} required/>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control type="password" placeholder="Confirm Password" minLength="6" onChange={(e) => setConfirmPassword(e.target.value)} required/>
                    </Form.Group>
                    <Button variant="primary" type="submit" value="Submit" className="float-right">Add User</Button>
                    <Link to="/users"><Button variant="secondary" type="button" className="float-right mr-2">Cancel</Button></Link>
                </Form>
            </Container>
        </>
    )
}