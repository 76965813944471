import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { NavDropdown, Container, Navbar, Nav, Collapse, Row, Col, Button, Modal } from 'react-bootstrap';
import { HamburgerSliderReverse } from 'react-animated-burgers';
import Cookies from 'js-cookie';
import axios from 'axios';
export default function NavBar(props) {
    const [isActive, setIsActive] = useState(false)
    const [links, setLinks] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {setShow(true); toggleButton()};
    const toggleButton = useCallback(
        () => setIsActive(prevState => !prevState),
        [],
    )
    useEffect(() => {
        axios.get('/api/v1/page/getLinks.php')
            .then(function (response) {
                if (response.data.message === "OK") {
                    for (var i = 0, len = Object.keys(response.data.pages).length; i < len; ++i) {
                        let title = String(response.data.pages[i].title)
                        let link
                        if (title === 'Home') {
                            link = '/'
                        } else {
                            link = '/page/' + title.split(' ').join('-');
                        }
                        setLinks(links => links.concat(
                            <Nav.Link key={i} as={Link} onClick={toggleButton} to={link}>{response.data.pages[i].title}</Nav.Link>
                        ))
                        if (title === 'Home') {
                            setLinks(links => links.concat(
                                <Nav.Link key={-10} onClick={handleShow}>Bio</Nav.Link>
                            ))
                        }
                    }
                    setLinks(links => links.concat(
                        <Nav.Link key={i + 1} onClick={toggleButton} as={Link} to={'/contact'}>Contact</Nav.Link>
                    ))

                }
            }).catch(function (error) {
                alert("Error getting links" + error)
            });
    }, [])
    return (
        <>
            <Row>
                <Col>
                    <div className="hamburger-offset">
                        <Collapse in={isActive}>
                            <Container>
                                <Navbar className="d-lg-flex align-items-center">
                                    <Container className="navContainer">
                                        {links}
                                        <IsLoggedIn loginPage={props.loginPage} page={props.page}></IsLoggedIn>
                                    </Container>
                                </Navbar>
                            </Container>
                        </Collapse>
                    </div>
                </Col>
            </Row>
            <HamburgerSliderReverse className="float-left hamburger-icon"
                barColor="white"
                {...{ isActive, toggleButton }}
            />
            <div className="navbar-shadow">

            </div>
            <Modal show={show} onHide={handleClose} centered size="xl">
                <div><div role="button" id="close1" onClick={handleClose}>
                        <div className="close2">
                            <div className="close3"></div>
                        </div>
                    </div></div>
                <Modal.Body closeButton><img src="/api/temp/bio.jpg" className="modal-image"/></Modal.Body>
            </Modal>
        </>
    )
}
function IsLoggedIn(props) {
    let loggedIn = Cookies.get('Permission');
    if (props.loginPage === false) {
        if (loggedIn !== undefined) {
            let list = []
            if (props.page !== "home") {
                list.push(<NavDropdown.Item key={1} as={Link} to="/">Home</NavDropdown.Item>)
            } else {
                list.push(<NavDropdown.Item key={2} as={Link} to="/" active>Home</NavDropdown.Item>)
            }
            if (props.page !== "account") {
                list.push(<NavDropdown.Item key={1} as={Link} to="/account">My Account</NavDropdown.Item>)
            } else {
                list.push(<NavDropdown.Item key={2} as={Link} to="/account" active>My Account</NavDropdown.Item>)
            }
            if (props.page !== "pages") {
                list.push(<NavDropdown.Item key={3} as={Link} to="/pages">Pages</NavDropdown.Item>)
            } else {
                list.push(<NavDropdown.Item key={4} as={Link} to="/pages" active>Pages</NavDropdown.Item>)
            }
            if (props.page !== "users") {
                list.push(<NavDropdown.Item key={5} as={Link} to="/users">Users</NavDropdown.Item>)
            } else {
                list.push(<NavDropdown.Item key={6} as={Link} to="/users" active>Users</NavDropdown.Item>)
            }
            if (props.page !== "vaults") {
                list.push(<NavDropdown.Item key={7} as={Link} to="/vaults">Vaults</NavDropdown.Item>)
            } else {
                list.push(<NavDropdown.Item key={8} as={Link} to="/vaults" active>Vaults</NavDropdown.Item>)
            }
            return (
                <NavDropdown as={Link} title="Menu" className="main-nav-link" id="basic-nav-dropdown">
                    {list}
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={Logout}>Logout</NavDropdown.Item>
                </NavDropdown>
            )
        } else {
            return (
                null
            )
        }
    } else {
        return null;
    }
    function Logout() {
        axios.post('/api/v1/logout.php').then(function (response) {
            if (response.data.message === 'OK') {
                window.location.href = '/'
            } else {
                alert("Error logging out");
            }
        }).catch(function (error) {
            alert("Error logging out: " + error);
        })
    }
}
