import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone'
import NavBar from './Navbar';
import Masonry from 'react-masonry-component';
import { Link, useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from 'axios';
import { Form, Button, Container, Alert, Row, Col, Card, ProgressBar, Modal } from 'react-bootstrap';

export default function EditVault() {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [error, setError] = useState(null);
    const [files, setFiles] = useState([]);
    const [existingFiles, setExistingFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [show, setShow] = useState(false);
    const [deleteImageID, setDeleteImageID] = useState([null]);
    const [updateExisting, setUpdateExisting] = useState(false);
    const [captionUpdate, setCaptionUpdate] = useState([]);
    const [showCaptionModal, setShowCaptionModal] = useState(false);
    const closeCaptionModal = () => setShowCaptionModal(false);
    const openCaptionModal = () => setShowCaptionModal(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const params = useParams();
    const history = useHistory();


    useEffect(() => {
        axios.get('/api/v1/vault/get.php?id=' + params.id + '&images')
            .then(function (response) {
                if (response.data.message === "OK") {
                    setTitle(response.data.vault[0].title)
                    setDescription(response.data.vault[0].description)
                    setExistingFiles([])
                    for (var i = 0, len = Object.keys(response.data.images).length; i < len; ++i) {
                        let thumbnailURL = '/api/v1/image/getImage.php?id=' + response.data.images[i].id + '&thumbnail'
                        let imageURL = '/api/v1/image/getImage.php?id=' + response.data.images[i].id + '&full'
                        let aspect = response.data.images[i].width / response.data.images[i].height
                        let letNewHeight = ((parseFloat(getComputedStyle(document.documentElement).fontSize) * 18) / aspect)
                        setExistingFiles(existingFiles => existingFiles.concat(
                            <Card style={{ width: '18rem' }} key={response.data.images[i].id} className="addVaultCards m-2">
                                <div className="card-img-top">
                                    <LazyLoadImage
                                        alt="test"
                                        effect="blur"
                                        width='100%'
                                        className="my-auto mx-auto"
                                        height={letNewHeight}
                                        placeholderSrc={thumbnailURL}
                                        src={imageURL} /></div>
                                <Card.Body>
                                    <Form.Group>
                                        <Form.Control type="text" value={response.data.images[i].caption} name={response.data.images[i].id} onClick={e => { setCaptionUpdate([e.currentTarget.value, e.currentTarget.name]); openCaptionModal() }} placeholder="Image Caption" />
                                    </Form.Group>
                                    <Button variant="danger" onClick={DeleteFileModal} value={response.data.images[i].id}>Remove</Button>
                                </Card.Body>
                            </Card>))

                    }
                } else if (response.data.message === 'You must be signed in to perform that action') {
                    history.push('/login')
                } else {
                    setError(response.data.message)
                }
            })
            .catch(function (error) {
                setError(error.message)
            });
    }, [updateExisting])
    function DeleteFileModal(event) {
        let image = JSON.parse(event.currentTarget.value)
        setDeleteImageID(image)
        handleShow()
    }
    function DeleteImageRequest() {
        axios.post('/api/v1/image/delete.php', {
            id: deleteImageID
        }).then(function (response) {
            handleClose()
            if (response.data.message === "OK") {
                setUpdateExisting(!updateExisting)
            } else {
                setError(response.data.message)
            }
        })
    }
    function updateVault(e) {
        e.preventDefault();
        let fd = new FormData();
        fd.append('type', 'vault')
        fd.append('id', params.id)
        const captions = files.map(file => (
            file.caption
        ));
        fd.append('captions', JSON.stringify(captions))
        files.map((file) => {
            fd.append('File[]', file);
        });
        axios.post('/api/v1/vault/update.php', {
            id: params.id,
            title: title,
            description: description
        }).then(function (response) {
            if (response.data.message === "OK") {
                if (files && files.length > 0) {
                    var config = {
                        onUploadProgress: function (progressEvent) {
                            setUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                        }
                    };
                    axios.post('/api/v1/image/imageUpload.php', fd, config)
                        .then(function (response) {
                            if (response.data.message === 'OK') {
                                setError(null)
                                history.push('/vaults')
                            } else {
                                setError(response.data.message)
                            }
                        }).catch(function (error) {
                            setError(error.message)
                        })
                } else {
                    setError(null)
                    history.push('/vaults')
                }
            } else if (response.data.message === 'You must be signed in to perform that action') {
                history.push('/login')
            } else {
                setError(response.data.message)
            }
        }).catch(function (error) {
            setError(error.error)
        })
    }
    function RenderError() {
        if (error === null) {
            return null
        } else {
            return <Alert className="m-2" variant="warning">{error}</Alert>
        }
    }
    function Previews(props) {
        let tempFiles = files.slice(-1)[0]
        let index = 0;
        if (tempFiles !== undefined) {
            index = tempFiles.id + 1
        }
        const { getRootProps, getInputProps } = useDropzone({
            accept: 'image/*',
            onDrop: acceptedFiles => {
                setFiles(files => files.concat(acceptedFiles.map(file => Object.assign(file, {
                    preview: URL.createObjectURL(file)
                }, { id: index++ }))));
                for (let i = tempFiles + 1; i < files.length; i++) {
                    URL.revokeObjectURL(files[i].preview)
                }
            }
        });
        function DeleteFile(event) {
            let id = event.currentTarget.value
            id = parseInt(id)
            setFiles(files => files.filter(file => file.id !== id))
        }
        const thumbs = files.map(file => (
            <Card style={{ width: '18rem' }} key={file.id} className="addVaultCards m-2">
                <Card.Img variant="top" src={file.preview} />
                <Card.Body>
                    <Form.Group>
                        <Form.Control type="text" value={file.caption} onChange={(e) => file.caption = e.target.value} placeholder="Image Caption" />
                    </Form.Group>
                    <Button variant="danger" onClick={DeleteFile} value={file.id}>Remove</Button>
                </Card.Body>
            </Card>

        ));
        function NewFilesTitle() {
            if (Array.isArray(files) && files.length === 0) {
                return null
            } else {
                return (<h3>New Files:</h3>)
            }
        }
        return (
            <section className="container-fluid text-center">
                <Container>
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                </Container>
                {NewFilesTitle}
                <Masonry
                    className={'my-gallery-class new-images text-center'} // default ''
                    elementType={'ul'} // default 'div'
                    options={masonryOptions} // default {}
                    disableImagesLoaded={false} // default false
                    updateOnEachImageLoad={false}
                // default false and works only if disableImagesLoaded is false
                >
                    {thumbs}
                </Masonry>
            </section>
        );
    }
    function updateCaptionRequest() {
        axios.post('/api/v1/image/caption.php', {
            id: captionUpdate[1],
            caption: captionUpdate[0]
        }).then(function (response) {
            handleClose()
            if (response.data.message === "OK") {
                setUpdateExisting(!updateExisting)
                setError(null)
                closeCaptionModal()
            } else {
                closeCaptionModal()
                setError(response.data.message)
            }
        })
    }
    const masonryOptions = {
        transitionDuration: 0,
        fitWidth: true
    };
    const Progress = () => {
        if (uploadProgress === 0) {
            return null
        } else {
            return <ProgressBar now={uploadProgress} />
        }
    }
    return (
        <>
            <NavBar loginPage={false} page={"vaults"}></NavBar>
            <Container fluid>
                <Row>
                    <Col>
                        <Form onSubmit={updateVault}>
                            <Container className="login-form">
                                <h2 className="text-center p-2">Edit vault</h2>
                                <RenderError></RenderError>
                                <Progress />
                                <Form.Group>
                                    <Form.Label>Vault Title</Form.Label>
                                    <Form.Control type="text" placeholder="Title" maxLength="50" minLength="1" value={title} onChange={(e) => setTitle(e.target.value)} required />
                                </Form.Group>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Vault Description</Form.Label>
                                    <Form.Control as="textarea" placeholder="Vault Description" value={description} onChange={(e) => setDescription(e.target.value)} maxLength="500" rows="3" />
                                </Form.Group>

                                <p>Upload images below:</p>
                            </Container>
                            <Previews />
                            <Container>
                                <div className="p-2">
                                    <Button variant="primary" type="submit" value="Submit" className="float-right">Update Vault</Button>
                                    <Link to="/vaults"><Button variant="secondary" type="button" className="float-right mr-2">Cancel</Button></Link>
                                </div>
                            </Container>
                            <br></br><br></br>
                            <Container className="text-center">
                                <h2>Existing Files:</h2>
                            </Container>
                            <Masonry
                                className={'my-gallery-class'} // default ''
                                elementType={'ul'} // default 'div'
                                options={masonryOptions} // default {}
                                disableImagesLoaded={false} // default false
                                updateOnEachImageLoad={false}
                            // default false and works only if disableImagesLoaded is false
                            >
                                {existingFiles}
                            </Masonry>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete image?</Modal.Title>
                </Modal.Header>
                <Modal.Body><h2>The image will be permanently deleted from the website!</h2></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
          </Button>
                    <Button variant="danger" onClick={DeleteImageRequest}>
                        Delete
          </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showCaptionModal} onHide={closeCaptionModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Caption</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control autofocus type="text" value={captionUpdate[0]} onChange={(e) => { setCaptionUpdate([e.currentTarget.value, captionUpdate[1]]) }} placeholder="Image Caption" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeCaptionModal}>Cancel</Button>
                    <Button variant="primary" onClick={updateCaptionRequest}>Update</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}